'use client';

import { bffClient } from '@/bff-client';
import { ApolloProvider } from '@apollo/client';
import { ReactNode } from 'react';

export default function ApolloWrapperLayout({
  children,
}: {
  children: ReactNode;
}) {
  const client = bffClient.getInstance();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
