'use client';

import { useCountFinderQuery } from '@/generated';
import { useFormTranslation } from '@/hooks/i18n';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const INTERVAL_NUMBER = 100;

export default function RegisteredBetaCustomers({
  className,
}: {
  className?: string;
}) {
  const { data, loading } = useCountFinderQuery();
  const [count, setCount] = useState(0);
  const formTrans = useFormTranslation();
  const step = data?.countFinder
    ? Math.ceil(data.countFinder / INTERVAL_NUMBER)
    : 0;

  useEffect(() => {
    // Animate the count up to the actual value
    const max = data?.countFinder || 0;
    if (max) {
      let i = 0;
      const interval = setInterval(() => {
        i += step;
        setCount(i);

        if (i >= max) {
          setCount(max);
          clearInterval(interval);
        }
      }, 5);
    }
  }, [data]);

  return (
    <div
      className={twMerge(
        'mx-auto flex w-full items-center justify-center gap-2',
        loading && 'animate-pulse',
        className
      )}
    >
      <h1 className='flex items-center bg-gradient-to-r from-primary to-[var(--secondary)] bg-clip-text text-xl font-semibold text-transparent'>
        {count}&nbsp;
        <span className='align-middle text-xs font-medium lowercase'>
          {formTrans('RegisterBetaCustomer.PeopleRegistered')}
        </span>
      </h1>
      🔥
    </div>
  );
}
