import { getConfig } from '@/config';
import {
  ApolloClient,
  ApolloLink,
  DefaultContext,
  InMemoryCache,
  from,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';

const config = getConfig();
export const bffClient = (function () {
  let instance: ApolloClient<any>;

  function createInstance() {
    return new ApolloClient({
      link: createLink(),
      cache: new InMemoryCache(),
      connectToDevTools: config.connectToDevTools,
    });
  }

  const createLink = (wsAuthToken = '') => {
    const preflightMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers }: DefaultContext) => ({
        headers: {
          ...headers,
          'Apollo-Require-Preflight': 'true',
        },
      }));

      return forward(operation);
    });

    let graphQLLink = createUploadLink({
      uri: config.graphqlUrl || '',
      // Allow sending cookies over cross-origin requests
      // same-origin: Only send cookies for the current domain
      // include: Always send cookies, even for cross-origin requests
      credentials: 'include',
    });

    const wsUrl = `${config.graphqlWsUrl || ''}`;

    /**
     * @requires: This public for create websocket connection on static request
     */
    const wsLink = new GraphQLWsLink(
      createClient({
        url: wsUrl,
        connectionParams: {
          accessToken: wsAuthToken,
        },
      })
    );

    graphQLLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      graphQLLink
    );

    return from([preflightMiddleware, graphQLLink]);
  };

  return {
    getInstance: function () {
      if (!instance || typeof window === 'undefined') {
        instance = createInstance();
      }
      return instance;
    },

    addWsAuthToken: function (wsAuthToken: string) {
      const ins = this.getInstance();
      const link = createLink(wsAuthToken);
      ins.setLink(link);
    },
  };
})();
