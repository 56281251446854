import { TInfoTranslation, useInfoTranslation } from '@/hooks/i18n';
import { ReactNode } from 'react';

const GradientText = ({ children }: { children: ReactNode }) => (
  <span className='bg-gradient-to-r from-[var(--secondary)] to-primary bg-clip-text text-transparent'>
    {children}
  </span>
);

type TSlide = {
  title: ReactNode;
  alt: string;
  foreground: { src: string; className?: string }[];
  background?: string[];
};

type TCreateSlides = (t: TInfoTranslation) => TSlide[];

export const createSlides: TCreateSlides = (t: TInfoTranslation) => [
  {
    title: (
      <h1 className='max-w-80 text-center text-[28px] font-medium'>
        {t('Home.Slide.0.Text')}
        <GradientText> {t('Home.Slide.0.Emphasis')}</GradientText>
      </h1>
    ),
    alt: 'Join & host sports games at ease',
    foreground: [{ src: '/images/slides/fg-1.webp' }],
    background: ['/images/slides/bg-1.webp'],
  },
  {
    title: (
      <h1 className='max-w-64 text-center text-[28px] font-medium'>
        <GradientText>{t('Home.Slide.1.Emphasis')} </GradientText>
        {t('Home.Slide.1.Text')}
      </h1>
    ),
    alt: 'Swipe to find suitable games',
    foreground: [
      { src: '/images/slides/fg-2.webp' },
      {
        src: '/images/slides/thumb.webp',
        className: 'absolute z-40 w-40 top-1/3 animate-wiggle',
      },
    ],
  },
  {
    title: (
      <h1 className='max-w-80 text-center text-[28px] font-medium'>
        <GradientText>{t('Home.Slide.2.Emphasis')} </GradientText>
        {t('Home.Slide.2.Text')}
      </h1>
    ),
    alt: 'Create and manage your games',
    foreground: [{ src: '/images/slides/fg-3.webp' }],
  },
  {
    title: (
      <h1 className='max-w-64 text-center text-[28px] font-medium'>
        <GradientText>{t('Home.Slide.3.Emphasis')} </GradientText>
        {t('Home.Slide.3.Text')}
      </h1>
    ),
    alt: 'Build your own sports profile',
    foreground: [{ src: '/images/slides/fg-4.webp' }],
    background: ['/images/slides/bg-4.webp'],
  },
];

export function useOnBoardSlide() {
  const infoTrans = useInfoTranslation();
  return createSlides(infoTrans);
}
