import ApolloWrapperLayout from '@/app/[locale]/(apollo-client-wrapper)/layout';
import { Button } from '@/components/ui/button';
import { useOnBoardSlide } from '@/features/home/onboard-slide';
import { useCommonTranslation } from '@/hooks/i18n';
import { AppRoute } from '@/interfaces';
import { cn } from '@/lib/utils';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import RegisteredBetaCustomers from '../register-beta-customer/registered-beta-customers';

interface CarouselActionProps extends React.HTMLAttributes<HTMLDivElement> {
  slideIndex: number;
}

export default function CarouselAction({
  className,
  slideIndex,
  ...rest
}: CarouselActionProps) {
  const commonTrans = useCommonTranslation();
  const i18nSlides = useOnBoardSlide();
  const router = useRouter();
  return (
    <div
      className={cn('z-50 flex w-full flex-col items-center px-8', className)}
      {...rest}
    >
      {i18nSlides[slideIndex].title}

      <ApolloWrapperLayout>
        <RegisteredBetaCustomers />
      </ApolloWrapperLayout>
      <Button
        variant={'gradient'}
        className='mt-4 w-full text-[16px]'
        onClick={() => {
          router.push(AppRoute.GAMES);
        }}
      >
        {commonTrans('GetStarted')}
      </Button>
      <Link
        href={AppRoute.LOGIN}
        className='mt-3 rounded-none text-[16px] text-[#111111] underline underline-offset-8'
      >
        {commonTrans('Login')}
      </Link>
    </div>
  );
}
