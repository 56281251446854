'use client';

import {
  Carousel,
  CarouselContent,
  CarouselIndicator,
  CarouselItem,
} from '@/components/ui/carousel';
import CarouselAction from '@/features/home/carousel-action';
import { useOnBoardSlide } from '@/features/home/onboard-slide';
import { cn } from '@/lib/utils';
import { getKey } from '@/utils';
import Image from 'next/image';
import { useState } from 'react';

export default function OnboardCarousel() {
  const [slideIndex, setSlideIndex] = useState(0);
  const slides = useOnBoardSlide();

  return (
    <Carousel className='fit-screen flex w-full pt-4'>
      <div className='dark-pink-gradient z-5 pointer-events-none fixed right-[calc(35%-40svh)] top-0 aspect-square w-[80svh] opacity-20' />
      <div className='dark-orange-gradient z-5 pointer-events-none fixed -bottom-1/3 left-[calc(35%-40svh)] aspect-square w-[80svh] opacity-20' />
      <CarouselContent>
        {slides.map((content, index) => (
          <CarouselItem key={index} className='pl-1'>
            <div className='fit-screen flex items-start justify-center'>
              {content.foreground.map((fg, idx) => (
                <Image
                  key={getKey('fg', idx)}
                  src={fg.src}
                  alt={content.alt}
                  width={1000}
                  height={960}
                  priority
                  className={cn(
                    'z-30 max-h-[55svh] cursor-not-allowed object-contain object-top',
                    fg?.className
                  )}
                />
              ))}
              {content.background &&
                content.background.map((bg, idx) => (
                  <Image
                    key={getKey('bg', idx)}
                    src={bg}
                    alt={content.alt}
                    width={1000}
                    height={960}
                    priority
                    className='absolute left-0 right-0 top-0 z-20 h-full w-full cursor-not-allowed object-cover opacity-30'
                  />
                ))}
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <div className='absolute bottom-[5%] flex w-full flex-col items-center'>
        <CarouselIndicator onSlideChange={setSlideIndex} />
        <CarouselAction slideIndex={slideIndex} className='mt-3 max-w-md' />
      </div>
    </Carousel>
  );
}
