import { RumInitConfiguration } from '@datadog/browser-rum';

interface IEnvironmentConfig {
  [key: string]: {
    adminAppUrl: string;
    graphqlUrl: string;
    graphqlWsUrl: string;
    connectToDevTools: boolean;
    datadogRumConfig?: RumInitConfiguration;
  };
}

const EnvironmentConfig: IEnvironmentConfig = {
  local: {
    adminAppUrl: 'http://localhost:3001',
    graphqlUrl: 'http://localhost:5001/graphql',
    graphqlWsUrl: 'ws://localhost:5001/graphql',
    connectToDevTools: true,
  },
  development: {
    adminAppUrl: 'https://development-app.choiee.com',
    graphqlUrl: 'https://dev-api.choiee.com/graphql',
    graphqlWsUrl: 'wss://dev-api.choiee.com/graphql',
    connectToDevTools: true,
  },
  production: {
    adminAppUrl: 'https://app.choiee.com',
    graphqlUrl: 'https://api.choiee.com/graphql',
    graphqlWsUrl: 'wss://api.choiee.com/graphql',
    connectToDevTools: false,
    datadogRumConfig: {
      applicationId: 'edbf2ecf-2728-4ed1-8d32-68cdc1904035',
      clientToken: process.env.APP_DATADOG_RUM_CLIENT_TOKEN as string,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'ap1.datadoghq.com',
      service: 'choiee',
      env: process.env.APP_ENV as string,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 80,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    },
  },
};

export const getConfig = () => {
  const env = process.env.APP_ENV || 'local';

  return EnvironmentConfig[env];
};
